import {
	Flex,
	Tabs,
	Title,
} from '@opsdti-global-component-library/amgen-design-system';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useGetUserRole } from '../hooks/use-get-user-role';
import { Outlet, useNavigate } from 'react-router-dom';
import NotificationsLoader from '../components/reusable/notifications-loader';
import {
	activityLogAllowedEnvs,
	activityLogAllowedIds,
	notificationsTabs,
} from '../constants';
import { getDelegates } from '../services/get-delegates';
import { AppContext } from '../state/app-context';
import { DelegateList, DelegateResponse, TabKeys, UserRole } from '../types';
import ActivityLogPageContent from './activity-log-page/activity-log-page-content';
import AvailablePageContent from './available-page/available-page-content';
import './index.scss';
import ManagePageContent from './manage-page/manage-page-content';
import RequestLogPageContent from './request-log-page/request-log-page-content';
import SubscriptionsPageContent from './subscriptions-page/subscriptions-page-content';

const NotificationsRoot: React.FC = () => {
	const { userFullname, userRole, userId } = useContext(AppContext);

	const navigate = useNavigate();

	const [delegates, setDelegates] = useState<DelegateList | undefined>(
		undefined
	);
	const [allowedTabs, setAllowedTabs] = useState<
		| undefined
		| { children: React.ReactNode; key: string; label: React.ReactNode }[]
	>(undefined);
	const [requestsCount, setRequestsCount] = useState<number | undefined>(
		undefined
	);

	const isAllowedActivityLog =
		activityLogAllowedEnvs.includes(process.env.REACT_APP_NODE_ENV ?? '') &&
		activityLogAllowedIds.includes(userId ?? '');

	const requestLogTabLabel = useCallback(() => {
		return (
			<Flex align="center" gap={5} style={{ marginRight: '2px' }}>
				Request Log
				{requestsCount !== undefined && (
					<Tabs.Badge count={requestsCount} showZero={true} />
				)}
			</Flex>
		);
	}, [requestsCount]);

	// this custom hook gets the user's role and updates it in the AppContext
	useGetUserRole();

	// build the allowed tabs based on the user's role
	useEffect(() => {
		const defaultTabs: {
			children: React.ReactNode;
			key: string;
			label: React.ReactNode;
		}[] = [];

		if (userRole) {
			if (userRole !== UserRole.NONE) {
				// the subscribed and available tabs are common for all user roles whit are not none
				defaultTabs.push(
					{
						children: <SubscriptionsPageContent delegates={delegates ?? []} />,
						key: TabKeys.SUBSCRIBED_TAB_KEY,
						label: <span>Subscribed Notifications</span>,
					},
					{
						children: <AvailablePageContent delegates={delegates ?? []} />,
						key: TabKeys.AVAILABLE_TAB_KEY,
						label: <span>Available Notifications</span>,
					}
				);
				if (userRole === UserRole.ADMIN) {
					// aditionally, admins can view all tabs
					defaultTabs.push(
						{
							children: <ManagePageContent />,
							key: TabKeys.MANAGE_TAB_KEY,
							label: <span>Manage Users</span>,
						},
						{
							children: <RequestLogPageContent />,
							key: TabKeys.REQUEST_TAB_KEY,
							label: requestLogTabLabel(),
						}
					);
				} else if (userRole === UserRole.APPROVER) {
					// approvers can only see the request log apart from the common tabs
					defaultTabs.push({
						children: <RequestLogPageContent />,
						key: TabKeys.REQUEST_TAB_KEY,
						label: requestLogTabLabel(),
					});
				}
			}

			if (isAllowedActivityLog) {
				// the activity log tab is only restricted for a reduced set of users, since it is only for testing purposes
				defaultTabs.push({
					children: <ActivityLogPageContent />,
					key: TabKeys.ACTIVITY_LOG_KEY,
					label: <span>Activity Log</span>,
				});
			}

			setAllowedTabs(defaultTabs);
		}
	}, [delegates, userRole, isAllowedActivityLog, requestLogTabLabel]);

	const currentTab = window.location.pathname.split('/')[2] ?? ''; // set the current tab as the remaining path after /subscriptions or /subscriptions/

	// this useEffect manages the call of the get_delegates API
	useEffect(() => {
		if (
			userFullname &&
			[notificationsTabs.SUBSCRIBED, notificationsTabs.AVAILABLE].includes(
				currentTab
			)
		) {
			// only fetch the get_delegates API when on the subscriptions or available tabs
			getDelegates({})
				.then((response) => {
					const subscribersInfo: DelegateList = response.data.data.map(
						(delegate: DelegateResponse) => {
							return {
								name: delegate.delegate_name,
								user_id: delegate.delegate_id,
								is_delegatee: true,
							};
						}
					);
					subscribersInfo.unshift({
						name: `${userFullname.split(', ')[1]} ${
							userFullname.split(', ')[0]
						}`,
						user_id: '',
						is_delegatee: false,
					});
					setDelegates(subscribersInfo);
				})
				.catch((error) => console.log(error));
		}
	}, [userFullname, currentTab]);

	// This useEffect manages the redirection of the user to the unauthorized page
	useEffect(() => {
		if (userRole) {
			if (
				currentTab === notificationsTabs.MANAGE &&
				userRole !== UserRole.ADMIN
			) {
				// only admins can view the Manage Users tab
				navigate('/subscriptions/unauthorized');
			}
			if (
				currentTab === notificationsTabs.REQUEST &&
				userRole !== UserRole.ADMIN &&
				userRole !== UserRole.APPROVER
			) {
				// only admins or approvers can view the Request Log tab
				navigate('/subscriptions/unauthorized');
			}
			if (
				[notificationsTabs.SUBSCRIBED, notificationsTabs.AVAILABLE].includes(
					currentTab
				) &&
				userRole === UserRole.NONE
			) {
				// user role should at leat not be none in order to have access to the subscribed and available notifications tabs
				navigate('/subscriptions/unauthorized');
			}
		}
	}, [userRole, currentTab, navigate]);

	return !allowedTabs ? (
		<NotificationsLoader />
	) : (
		<>
			{currentTab !== notificationsTabs.UNAUTHORIZED && (
				//disply this header only when not in the unauthorized page
				<Flex className="subscriptions-header">
					<Title level={1} style={{ fontWeight: 600 }}>
						Manage Notifications
					</Title>
				</Flex>
			)}
			<Outlet context={{ allowedTabs, setRequestsCount }} />
		</>
	);
};

export default NotificationsRoot;
