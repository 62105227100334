import { useState } from 'react';
import { defaultValidationModalConfig } from '../constants';
import { SubscriptionAction, ValidationModalConfigType } from '../types';

export function useValidationModal() {
	const [validationModalConfig, setValidationModalConfig] =
		useState<ValidationModalConfigType>(defaultValidationModalConfig);

	function closeValidationModal() {
		setValidationModalConfig((prevConfig) => {
			return { ...prevConfig, open: false };
		});
	}

	function openValidationModal(
		action: SubscriptionAction,
		workstream?: string,
		subscriberName?: string,
		notificationName?: string,
		approvalStatus?: string
	) {
		if (action === SubscriptionAction.ADD_SUBSCRIBER) {
			setValidationModalConfig((prevConfig) => {
				return {
					...prevConfig,
					open: true,
					title: `Sensing ${workstream} Subscription - Subscribe`,
					status: 'SUCCESS',
					header: 'Your subscription is now pending.',
					content:
						'Subscriptions can take up to two minutes to become active. View your active notifications under the "Subscribed Notifications" tab.',
				};
			});
		} else if (action === SubscriptionAction.UPDATE_SUBSCRIBER) {
			setValidationModalConfig((prevConfig) => {
				return {
					...prevConfig,
					open: true,
					title: `Sensing ${workstream} Subscription - Edit`,
					status: 'UPDATE',
					header: '',
					content: '',
				};
			});
		} else if (action === SubscriptionAction.DELETE_SUBSCRIBER) {
			setValidationModalConfig((prevConfig) => {
				return {
					...prevConfig,
					open: true,
					title: 'Unsubscribe',
					status: 'WARNING',
					header: `Unsubscribe "${subscriberName}" from\n${workstream} - ${notificationName}?`,
					content:
						'Unsubscribing will remove the notification from "Subscribed Notifications" and you will have to subscribe again from the "Available Notificiations" tab.',
				};
			});
		} else if (action === SubscriptionAction.APPROVE_SUBSCRIBER) {
			setValidationModalConfig((prevConfig) => {
				return {
					...prevConfig,
					open: true,
					title: `Notification - ${approvalStatus}`,
					status: 'SUCCESS',
					header: 'The notification has been approved.',
					content: '',
				};
			});
		} else if (action === SubscriptionAction.DENY_SUBSCRIBER) {
			setValidationModalConfig((prevConfig) => {
				return {
					...prevConfig,
					open: true,
					title: `Notification - ${approvalStatus}`,
					status: 'SUCCESS',
					header: 'The notification has been denied.',
					content: '',
				};
			});
		} else if (action === SubscriptionAction.WARN_DENY_AND_RESEND_REQUEST) {
			setValidationModalConfig((prevConfig) => {
				return {
					...prevConfig,
					open: true,
					title: 'Re-send Notification',
					status: 'WARNING',
					header: 'Deny and re-send this notification?',
					content:
						'This current notification will not be sent and a new approval request will be created and be pending approval.',
				};
			});
		} else if (action === SubscriptionAction.WARN_RESEND_REQUEST) {
			setValidationModalConfig((prevConfig) => {
				return {
					...prevConfig,
					open: true,
					title: 'Re-send Notification',
					status: 'WARNING',
					header: 'Re-send this notification?',
					content:
						'A new approval request with the latest data will be created and be pending approval.',
				};
			});
		} else if (action === SubscriptionAction.RESEND_REQUEST) {
			setValidationModalConfig((prevConfig) => {
				return {
					...prevConfig,
					open: true,
					title: 'Re-send Notification',
					status: 'SUCCESS',
					header: 'New Approval Request Created',
					content:
						'This notification was not sent. A new approval request has been created and will be displayed in the "Request Log" tab.',
				};
			});
		} else if (action === SubscriptionAction.RETRIGGER_IS_PROCESSING) {
			setValidationModalConfig((prevConfig) => {
				return {
					...prevConfig,
					open: true,
					title: 'Re-send Notification',
					status: 'WARNING',
					header: '',
					content:
						'Please check the request log later for a new approval request.',
				};
			});
		} else if (action === SubscriptionAction.RETRIGGER_IS_IN_QUEUE) {
			setValidationModalConfig((prevConfig) => {
				return {
					...prevConfig,
					open: true,
					title: 'Re-send Notification',
					status: 'WARNING',
					header: '',
					content: 'No further action is required at this time.',
				};
			});
		}
	}

	return {
		validationModalConfig,
		openValidationModal,
		closeValidationModal,
	};
}
