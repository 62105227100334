// API request related types

/**
 * This is the parameter type for the fetchNotificationsList function.
 * @param data is the request body for the fetch_notifications and fetch_ad_notifications APIs calls.
 * @param type is the subscription type used to filter among subscribed and unsubscribed notifications.
 */
export interface FetchNotificationsRequest {
	data: NotificationsListRequestData;
	type: SubscriptionType;
	getAD: boolean;
}

export enum UserType {
	USER = 'user',
	AD = 'AD',
}

/**
 * This enum is used to determine the type of a notification's subscription.
 */
export enum SubscriptionType {
	SUBSCRIBED = 'SUBSCRIBED',
	UNSUBSCRIBED = 'UNSUBSCRIBED',
}

/**
 * This is the type for the request body of the fetch_notifications and fetch_ad_notifications APIs.
 */
export interface NotificationsListRequestData {
	is_admin: boolean;
	is_delegatee: boolean;
	delegate_user_id?: string;
	filters?: object;
	subscribed_flag?: boolean;
	delegate_user_type: UserType.USER | UserType.AD;
}

/**
 * This is the type for the request body of the get-table-entries APIs.
 */
export interface SubscriberTableEntriesRequestData {
	is_User: boolean;
}

/**
 * This is the parameter type for the manageSubscriptions function.
 * @param data is the request body for the request_widget_subscription API call.
 * @param notification is the object of the notification for which the subscription request is being made.
 */
export interface ManageSubscriptionsRequest {
	data: ManageSubscriptionsRequestData;
	notification: FetchNotificationResponse;
}

/**
 * This is the type for the resquest body of the request_widget_subscription API.
 */
export interface ManageSubscriptionsRequestData {
	is_admin?: boolean;
	user_full_name: string;
	notifications: SubscriptionNotificationType[];
	is_delegatee: boolean;
	delegate_user_id: string;
	delegate_type: UserType.AD | UserType.USER;
}

/**
 * This is the type for the resquest body of the request_deny_and_retrigger API.
 */
export interface RetriggerNotificationRequestData {
	event_id: string;
	event_log_id: string;
	requester_id: string;
	requester_name: string;
}

/**
 * This is the type which lists the required properties of the notifications list sent to the request_widget_subscription API.
 */
export interface SubscriptionNotificationType {
	notification_id: string;
	user_frequency: string;
	delivery_channel: string[];
	is_enabled: boolean;
	action_type: string;
}

/**
 * This is the type for the resquest body of the fetch_ad_users API.
 */
export interface FetchAdUsersRequestData {
	ad_group: string;
}

// API response related types

/**
 * Response type for the fetch_notifications API.
 */
export interface FetchNotificationResponse {
	allowed_groups: string[];
	allowed_users: string[];
	application_name: string;
	arche_type: string;
	channel_id: string[];
	creation_time: string;
	creator: string;
	denied_users: string[];
	description: string;
	entity: string;
	entity_type: string;
	event_id: string[];
	frequency: string;
	id: string;
	is_subscribed: boolean;
	is_enabled: boolean;
	is_processing: boolean;
	name: string;
	notification_active: boolean;
	type: string;
	workstream: string;
	metric_details: string;
	subscribed_channels: string[];
	notification_template: NotificationTemplate[];
	is_ad_group?: boolean;
	ad_group?: string;
	selected_channels?: string[];
}

interface NotificationTemplate {
	body: {
		S: string;
	};
	channel_name: {
		S: string;
	};
	channel: {
		S: string;
	};
}

/**
 * This is the type for the response body of the fetch_ad_notifications API.
 */
export interface FetchADNotificationsResponse {
	notification_id: string;
	subscription_type: string;
	is_processing: boolean;
	subscribed_channels: string[];
	ad_group: string;
}

/**
 * This is the type for the response body of the get-table-entries API.
 */
export interface FetchGetTableEntriesResponse {
	userid: string;
	full_name: string;
	active_subscriptions: number;
}

/**
 * Response type for the auth graphql API
 */
export interface TabsAuthResponse {
	availableNotifications: boolean;
	managedUsers: boolean;
	requestLog: boolean;
	subscribedNotifications: boolean;
}

/**
 * List of admissible user's role
 */
export type AdmissibleUserRoles =
	| undefined
	| 'NONE'
	| 'REGULAR'
	| 'APPROVER'
	| 'ADMIN';

export type RequestLogTableData = {
	event_log_id: string;
	event_id: string;
	time_to_live: number;
	approval_required: boolean;
	approvers_to_list: string[];
	approvers_cc_list: string[];
	approval_not_required_viewers: string[];
	request_date: string;
	event_name: string;
	event_description: string;
	workstream: string;
	approval_status: string;
	delivery_status: string;
	delivery_count: string;
	token: string;
};

export type RequestLogTableResponse = Array<RequestLogTableData>;

export type RequestLogModalData = {
	event_log_id: string;
	event_id: string;
	notification_id: string;
	approval_status: string;
	delivery_status: string;
	digest_notification: boolean;
	delivery_count: string;
	is_processing: boolean;
	request_date: string;
	date_type: string;
	date: string;
	reviewer_id: string;
	reviewer_name: string;
	event_name: string;
	notification_name: string;
	event_description: string;
	trigger: string;
	frequency: string;
	is_approval_valid: boolean;
	is_retrigger_valid: boolean;
	is_self_managed: boolean;
	modal_access: boolean;
	delivery_messages: {
		messages: {
			channel: string;
			message: string[];
		}[];
	};
	recipients: [
		{
			user_name: string;
			full_name: string;
			user_type: string;
			delivery_channel: string[];
			subscription_delivery_status: { [key: string]: string };
			users_count: string;
		}
	];
};
