import { fetchApi } from '../utils/services';
import { RetriggerNotificationRequestData } from '../types';

export const retriggerNotification = async (
	data: RetriggerNotificationRequestData
) => {
	const response = await fetchApi({
		header: {},
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}\\retrigger/request_deny_and_retrigger`,
		data,
	});

	return response;
};
