import {
	Button,
	Flex,
	Table,
	Text,
} from '@opsdti-global-component-library/amgen-design-system';
import { notAvailable } from '../../../constants';
import { RequestLogTableData } from '../../../types';
import { convertUTCToPST } from '../../../utils/convert-utc-to-pst';
import sortData from '../../../utils/sort-data';
import './index.scss';
import DeliveryStatusDefinition from '../../delivery-status-definition';

interface RequestLogTableProps {
	data: Array<RequestLogTableData>;
	loading: boolean;
	onRequestClick: (data: string) => void;
}

const RequestLogTable = ({
	data,
	loading,
	onRequestClick,
}: RequestLogTableProps) => {
	const getActionButton = (selectedRow: RequestLogTableData) => {
		return (
			<Flex justify="center">
				<Button
					className={'request-log-table-button'}
					text={
						selectedRow?.approval_status.includes('Ready for Review')
							? 'Review'
							: 'View'
					}
					onClick={() => {
						onRequestClick(selectedRow?.event_log_id);
					}}
					type={
						selectedRow?.approval_status.includes('Ready for Review')
							? 'primary'
							: 'secondary'
					}
				/>
			</Flex>
		);
	};

	const getDeliveryStatus = (selectedRow: RequestLogTableData) => {
		return ['Succeeded', 'Failed'].includes(selectedRow?.delivery_status) &&
			notAvailable.includes(selectedRow?.delivery_count) ? (
			<></>
		) : (
			<DeliveryStatusDefinition
				status={
					notAvailable.includes(selectedRow?.delivery_status)
						? ''
						: ['Pending', 'Processing', 'Cancelled'].includes(
								selectedRow?.delivery_status
						  )
						? 'missing'
						: selectedRow?.delivery_status === 'Succeeded'
						? 'success'
						: 'error'
				}
				text={
					['Succeeded', 'Failed'].includes(selectedRow?.delivery_status)
						? selectedRow?.delivery_count === 'NA'
							? 'Not Available'
							: selectedRow?.delivery_count
						: notAvailable.includes(selectedRow?.delivery_status)
						? 'Not available'
						: selectedRow?.delivery_status
				}
				type={
					['Pending', 'Processing', 'Cancelled'].includes(
						selectedRow?.delivery_status
					)
						? 'secondary'
						: 'primary'
				}
			/>
		);
	};

	return (
		<div>
			<Table
				showSorterTooltip={false}
				columns={[
					{
						key: 'request_date',
						dataIndex: 'request_date',
						render: (_, rowData) => convertUTCToPST(rowData?.request_date),
						title: (
							<Text strong className="subscriptions-table-column">
								REQUEST DATE
							</Text>
						),
						width: '172px',
						sortDirections: ['descend', 'ascend', 'descend'],
						defaultSortOrder: 'ascend',
						sorter: (a, b) => {
							const dateDiff =
								new Date(b?.request_date).getTime() -
								new Date(a?.request_date).getTime();

							return dateDiff !== 0 ? dateDiff / Math.abs(dateDiff) : dateDiff;
						},
						ellipsis: true,
					},
					{
						key: 'workstream',
						dataIndex: 'workstream',
						title: (
							<Text strong className="subscriptions-table-column">
								PAGE
							</Text>
						),
						width: '75px',
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: (a, b, sortOrder) =>
							sortData(a?.workstream, b?.workstream, sortOrder),
					},
					{
						key: 'event_name',
						dataIndex: 'event_name',
						title: (
							<Text strong className="subscriptions-table-column">
								NAME
							</Text>
						),
						width: '345px',
						sortDirections: ['descend', 'ascend', 'descend'],
						sorter: (a, b, sortOrder) =>
							sortData(a?.event_name, b?.event_name, sortOrder),
						ellipsis: true,
					},
					{
						key: 'approval_status',
						dataIndex: 'approval_status',
						title: (
							<Text strong className="subscriptions-table-column">
								APPROVAL STATUS
							</Text>
						),
						width: '190.5px',
					},
					{
						key: 'delivery_status',
						dataIndex: 'delivery_status',
						render: (_, row_data) => getDeliveryStatus(row_data),
						title: (
							<Text strong className="subscriptions-table-column">
								DELIVERY STATUS
							</Text>
						),
						width: '190.5px',
					},
					{
						key: 'action',
						dataIndex: 'action',
						render: (_, data1) => getActionButton(data1),
						title: (
							<Text strong className="subscriptions-table-column">
								ACTION
							</Text>
						),
						width: '94px',
						fixed: 'right',
					},
				]}
				dataSource={data}
				onChange={() => {}}
				scroll={{
					x: 800,
				}}
				style={{
					width: '100%',
				}}
				pagination={{
					position: ['none', 'bottomRight'],
					showSizeChanger: true,
					showTotal: (total: number, range: [number, number]) =>
						`${range[0]}-${range[1]} of ${total} items`,
				}}
				loading={loading}
			/>
		</div>
	);
};

export default RequestLogTable;
