import { useContext, useEffect } from 'react';
import { getTabAuth } from '../services/get-tab-auth';
import { AppContext } from '../state/app-context';
import { TabsAuthResponse, UserRole } from '../types';

export const useGetUserRole = () => {
	const { userId, setUserRole } = useContext(AppContext);

	useEffect(() => {
		getTabAuth({
			query: `query {\n  sensingAuthorization(username:"${userId}", workstreams:["notifications"],\n    skipWorkstreamLevelAuth: false){\n notifications notificationsAuthorization {\n  managedUsers requestLog subscribedNotifications availableNotifications }\n}\n}`,
			variables: null,
		}).then((response) => {
			const tabsAuth: TabsAuthResponse =
				response?.data?.data?.sensingAuthorization?.notificationsAuthorization;

			if (tabsAuth.availableNotifications && tabsAuth.subscribedNotifications) {
				if (tabsAuth.managedUsers && tabsAuth.requestLog) {
					setUserRole(UserRole.ADMIN);
				} else if (!tabsAuth.managedUsers && tabsAuth.requestLog) {
					setUserRole(UserRole.APPROVER);
				} else {
					setUserRole(UserRole.REGULAR);
				}
			} else {
				setUserRole(UserRole.NONE);
			}
		});
	}, [setUserRole, userId]);
};
